"use strict";

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import axios from "axios";
import {
  throttle,
  get,
  omit,
  pick,
  mapValues,
  isEmpty,
  isEqual,
  merge,
} from "lodash";
import { urlStrToJSON, JSONtoUrlStr } from "./../lib/parse";
import { initialFilters } from "../lib/filter";
import { WOO_PRODUCT_LIMIT } from "./../lib/globals";

import fetchProducts from "./../actions/products/fetchProducts";
import fetchMoreProducts from "./../actions/products/fetchMoreProducts";
import fetchFilters from "./../actions/filters/fetchFilters";
import setProductParams from "./../actions/products/setProductParams";
import setFavourites from "../actions/favourites/setFavourites";

import App from "./../components/AppComponent.js";
import { has } from "underscore";

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.getScrollOffset = this.getScrollOffset.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.getUrlParams = this.getUrlParams.bind(this);
    this.getUrlSlug = this.getUrlSlug.bind(this);
    this.resetPageParam = this.resetPageParam.bind(this);
    this.setPageToUrlParam = this.setPageToUrlParam.bind(this);
    this.isSingleProduct = this.isSingleProduct.bind(this);
    this.isProductCatPage = this.isProductCatPage.bind(this);
    this.isCollectionPage = this.isCollectionPage.bind(this);
    this.isArtistOrCatPage = this.isArtistOrCatPage.bind(this);
    this.isFavouritesPage = this.isFavouritesPage.bind(this);
    this.isCampaignPage = this.isCampaignPage.bind(this);
    this.isArtWallPage = this.isArtWallPage.bind(this);
    this.is404Page = this.is404Page.bind(this);
    this.getCurrentDefaultParams = this.getCurrentDefaultParams.bind(this);
    this.isLimitedEditionsOrWallObjectsCatPage =
      this.isLimitedEditionsOrWallObjectsCatPage.bind(this);
  }

  getScrollOffset() {
    const bottom =
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.body.scrollHeight * 0.85;
    return bottom;
  }

  handleScroll(e) {
    const is404Page = this.is404Page();
    const bottom = this.getScrollOffset();
    const { isFetching, isFetchingMore, allFetched } = this.props.products;
    // console.log('isFetching', isFetching);
    // console.log('isFetchingMore', isFetchingMore);
    // console.log('allFetched', allFetched);
    if (bottom && !isFetching && !isFetchingMore && !allFetched && !is404Page) {
      const params = this.getCurrentDefaultParams();
      // console.log('params', params);
      const pos = this.getUrlParams({ key: "pos" });
      const page = this.getUrlParams({ key: "page" });
      // console.log('page', page);
      // console.log('what is the curr params== ??', allFetched, params, pos, page);

      this.props.actions.fetchMoreProducts(params);
      // @TODO - should we set page param on scroll, or only if navigating (leans towards the later)
      // this.setPageToUrlParam()
    }
  }

  getUrlSlug() {
    const pathname = this.props.pathname;
    const slug = pathname.replace(/\//g, "");
    return slug;
  }

  getCurrentDefaultParams() {
    const { routing, pathname } = this.props;
    const { page } = this.props.products;
    const { filterParams } = this.props.filters;
    const isSingleProduct = this.isSingleProduct();
    const isProductCatPage = this.isProductCatPage();
    const isCollectionPage = this.isCollectionPage();
    const isArtistOrCatPage = this.isArtistOrCatPage();
    const isFavourites = this.isFavouritesPage();
    const isCampaign = this.isCampaignPage();
    const isArtWallPage = this.isArtWallPage();
    const is404Page = this.is404Page();
    const isLimitedEditionsOrWallObjectsCatPage =
      this.isLimitedEditionsOrWallObjectsCatPage();

    console.log(
      "isLimitedEditionsOrWallObjectsCatPage",
      isLimitedEditionsOrWallObjectsCatPage
    );

    let params = {};
    if (isSingleProduct && !is404Page) {
      console.log("isSingleProduct");
      const isOutOfStock = window.tpcProductStock || false;
      const category =
        isOutOfStock === "outofstock"
          ? window?.tpcProductCats[0]
          : window?.tpcProductCats?.indexOf("transparent-prints") > -1
          ? "transparent-frames"
          : "frames";
      params = {
        page: 1,
        mainGrid: isOutOfStock === "outofstock" ? true : false,
        filterParams: {
          ...(isOutOfStock !== "outofstock" && { category: category }),
          ...(isOutOfStock === "outofstock" && { sortby: "5" }),
        },
        limit: 9,
      };
      // console.log('single product', params)
    } else if (isProductCatPage) {
      console.log("isProductCatPage");
      const { query } = this.props;
      console.log("query", query, isLimitedEditionsOrWallObjectsCatPage);

      // console.log('is the cat page yes')
      const slug = this.getUrlSlug();
      params = {
        page: page || 1,
        mainGrid: false,
        filterParams: {
          category: slug,
          ...(query.hasOwnProperty("size") && { size: query.size }),
          ...(isLimitedEditionsOrWallObjectsCatPage && {
            show_out_of_stock: true,
            orderby: "menu_order",
          }),
        },
      };
      console.log("params", params);
    } else if (isCollectionPage) {
      console.log("isCollectionPage");
      const root = document.getElementById("app");
      const dataAttributes = root.dataset.collectionAttributes;
      const attributes = JSON.parse(dataAttributes);
      const dataCategories = root.dataset.collectionCategories;
      const categories = JSON.parse(dataCategories);
      // console.log('before attributes', attributes);
      // console.log('before categories', categories);
      if (attributes.hasOwnProperty("sort_by")) {
        delete Object.assign(attributes, { ["sortby"]: attributes["sort_by"] })[
          "sort_by"
        ];
      }
      // console.log('after attributes', attributes);
      const filterParams = mapValues(attributes, (t) => {
        if (Array.isArray(t)) {
          return t.join("&");
        } else {
          return t;
        }
      });
      if (!isEmpty(categories)) {
        merge(filterParams, categories);
      }
      // console.log('filterParams', filterParams);
      params = {
        page: page || 1,
        mainGrid: isEmpty(categories) ? true : false,
        filterParams: filterParams,
      };
    } else if (isArtistOrCatPage) {
      console.log("isArtistOrCatPage");
      const root = document.getElementById("app");
      let taxonomySlug = root.getAttribute("data-taxonomy_slug");
      const termId = root.getAttribute("data-term_id");
      taxonomySlug = taxonomySlug.replace("pa_", "");
      // console.log('tax slug', taxonomySlug)

      params = {
        page: 1,
        mainGrid: false,
        filterParams: {
          [taxonomySlug]: termId,
        },
      };
    } else if (isCampaign) {
      console.log("isCampaign");
      let productIds = window.campaignPageProductIds;
      console.log({ productIds });
      params.product_ids = productIds;
      params.page = page || 1;
      // console.log('the params', params)
    } else if (isFavourites) {
      console.log("isFavourites");
      // console.log('this favourites page here', isFavourites)
      const hashUrlParam = get(this.props.query, "h", false);
      const addToCartUrlParam = get(this.props, 'query["add-to-cart"]', false);
      if (!!hashUrlParam) {
        params.favouritesHash = hashUrlParam;
        params.page = page || 1;
      } else {
        let favouriteIds = localStorage.getItem("favouriteIds")
          ? JSON.parse(localStorage.getItem("favouriteIds"))
          : [];
        // console.log('YOLO favouriteIds YOLO', favouriteIds);
        favouriteIds = favouriteIds.map((f) => f.id);
        // console.log('no hash, get from local', favouriteIds)
        params.favouriteIds = favouriteIds;
        params.page = page || 1;
        // console.log('the params', params)
        if (addToCartUrlParam) {
          this.props.dispatch(push(this.props.pathname));
        }
      }
    } else if (isArtWallPage) {
      console.log("isArtWallPage");
      // let favouriteIds = localStorage.getItem('favouriteIds') ? JSON.parse(localStorage.getItem('favouriteIds')) : []

      // if (!isEmpty(favouriteIds)) {
      //     favouriteIds = favouriteIds.map(f => f.id)

      //     params.favouriteIds = favouriteIds
      //     params.page = page || 1
      // } else {
      //     params.filterParams = filterParams
      //     params.page = page
      // }
      params = {
        page: page,
        filterParams: {
          exclude_cats: ["transparent-prints"],
        },
      };
      // } else if (is404Page) {
    } else if (is404Page) {
      console.log("is404Page");

      if (pathname.includes("/artists")) {
        // params = {
        //   page: 1,
        //   mainGrid: true,
        //   filterParams: {
        //     sortby: "5",
        //   },
        //   limit: 6,
        // };
      } else if (pathname.includes("/product")) {
        params = {
          page: 1,
          mainGrid: true,
          filterParams: {
            sortby: "5",
          },
          limit: 12,
        };
      } else {
        params = {
          page: 1,
          mainGrid: true,
          filterParams: {
            sortby: "5",
          },
          limit: 12,
        };
      }
    } else {
      console.log("isNoneOfTheAbove");
      // console.log('the front page no sticky bizx', filterParams)

      // console.log('YOLOMOLO routing', routing);
      params.filterParams = filterParams;
      params.page = page;
      if (get(routing, "locationBeforeTransitions.query.s")) {
        params.search = routing.locationBeforeTransitions.query.s;
      }
    }

    const pageFromUrl = this.getUrlParams({ key: "page" });
    let limit = false;
    if (pageFromUrl) {
      limit = parseInt(pageFromUrl) * WOO_PRODUCT_LIMIT;
      params.limit = limit;
      params.page = 1;
    }

    return params;
  }

  getUrlParams({ key = "" }) {
    // const { routing } = this.props
    // const { locationBeforeTransitions } = routing
    // const { search } = locationBeforeTransitions
    const searchObj = urlStrToJSON(this.props.search);
    // console.log('searchObj', searchObj);
    let params = searchObj;
    if (key) {
      params = get(searchObj, key, false);
    }
    return params;
  }

  isSingleProduct() {
    const pathname = this.props.pathname;
    return pathname.indexOf("product") > -1;
  }

  isProductCatPage() {
    const slug = this.getUrlSlug();
    const catPageSlugs = window.tpcCatPages || [];
    // console.log('the product cat pages', slug, catPageSlugs)
    return catPageSlugs.indexOf(slug) > -1;
  }

  isCollectionPage() {
    const pathname = this.props.pathname;
    return pathname.indexOf("collection") > -1;
  }

  isArtistPage() {
    const pathname = this.props.pathname;
    return pathname.indexOf("artists") > -1;
  }

  isArtistOrCatPage() {
    const pathname = this.props.pathname;
    return (
      pathname.indexOf("artists") > -1 || pathname.indexOf("categories") > -1
    );
  }

  isLimitedEditionsOrWallObjectsCatPage() {
    const pathname = this.props.pathname;
    return (
      pathname.indexOf("limited-editions") > -1 ||
      pathname.indexOf("wall-objects") > -1
    );
  }

  isFavouritesPage() {
    const pathname = this.props.pathname;
    return pathname.indexOf("favourites") > -1;
  }

  isCampaignPage() {
    const pathname = this.props.pathname;
    // console.log('YOOLOLOLOLOLO', pathname);
    // console.log('YOOLOLOLOLOLO', pathname.replace(/\//g, ''));
    // console.log('YOOLOLOLOLOLO', window.campaignPages);
    // if (window.campaignPages) {
    //     console.log('YOOLOLOLOLOLO', window.campaignPages.indexOf(pathname.replace(/\//g, '')) > -1);
    //     return window.campaignPages.indexOf(pathname.replace(/\//g, '')) > -1
    // } else {
    //     return false
    // }

    return window.campaignPages
      ? window.campaignPages.indexOf(pathname.replace(/\//g, "")) > -1
      : false;
  }

  isArtWallPage() {
    const pathname = this.props.pathname;
    return pathname.indexOf("art-wall-designer") > -1;
  }

  is404Page() {
    // const pathname = this.props.page === "404";
    // return pathname;

    return document.body.classList.contains("error404") ?? false;
  }

  setPageToUrlParam() {
    const { page } = this.props.products;
    const pathname = this.props.pathname;
    let params = this.getUrlParams({ key: false });
    params.page = page;
    let search = JSONtoUrlStr(params);
    search = search == "?" ? "" : search;
    // console.log('set the page to the url', page, pathname, params, search);
    setTimeout(() => {
      // console.log('this be the destination', `${pathname}${search}`)
      this.props.dispatch(push(`${pathname}${search}`));
    }, 0);
  }

  resetPageParam() {
    const pathname = this.props.pathname;
    let params = this.getUrlParams({ key: false });
    params = omit(params, "page");
    let search = JSONtoUrlStr(params);
    search = search == "?" ? "" : search;
    setTimeout(() => {
      this.props.dispatch(push(`${pathname}${search}`));
    }, 0);
  }

  // UNSAFE_componentWillMount(){
  //     const params = this.getCurrentDefaultParams()
  //     // console.log('params before fetch', params)
  //     console.log('*** UNSAFE_componentWillMount *** fetch the prods', params)
  //     this.props.actions.fetchProducts(params);
  //     this.props.actions.fetchFilters();
  //     // this.resetPageParam()
  // }

  componentDidMount() {
    // console.log('add the scroll to the (window) handler on the did mount')

    const params = this.getCurrentDefaultParams();
    // console.log('params before fetch', params)
    // console.log('*** ComponentDidMount *** fetch the prods', params)
    if (!this.props.filters.isFetching) {
      this.props.actions.fetchFilters();
    }
    if (
      !this.props.products.isFetching &&
      !this.props.products.isFetchingMore
    ) {
      const { query } = this.props;
      // console.log('the qy', query, initialFilters)
      const currQuery = pick(query, Object.keys(initialFilters));
      if (isEmpty(currQuery) && !has(query, "s")) {
        // console.log('fetch from empty query', currQuery, query)
        this.props.actions.fetchProducts(params);
      }
    }

    const isSingleProduct = this.isSingleProduct();
    const isCollectionPage = this.isCollectionPage();
    const isArtistOrCatPage = this.isArtistOrCatPage();
    const favouriteIds = localStorage.getItem("favouriteIds");

    if (favouriteIds) {
      const parsedFavouriteIds = JSON.parse(favouriteIds);
      this.props.actions.setFavourites(parsedFavouriteIds);
    } else {
      this.props.actions.setFavourites([]);
    }

    // if (!isSingleProduct && !isArtistOrCatPage && !isCollectionPage) {
    //     window.addEventListener('scroll', throttle(this.handleScroll, 250))
    // }
    if (!isSingleProduct && !isArtistOrCatPage) {
      window.addEventListener("scroll", throttle(this.handleScroll, 250));
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('pathname', this.props.pathname);
    // console.log('search', this.props.search);
    // console.log('query', this.props.query);
    const prevFilterParams = omit(prevProps.filters.filterParams, "page");
    const currFilterparams = omit(this.props.filters.filterParams, "page");

    if (
      (this.props.pathname !== prevProps.pathname ||
        !isEqual(currFilterparams, prevFilterParams)) &&
      !this.props.products.clicked
    ) {
      console.log("SCROLLING");
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    // console.log('remove the scroll handler NOW')
    const isSingleProduct = this.isSingleProduct();
    if (!isSingleProduct) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  render() {
    // console.log('his.is404Page()', this.is404Page());
    return <App is404={this.is404Page()} />;
  }
}

let mapStateToProps = (state) => {
  const props = {
    products: state.products,
    filters: state.filters,
    favourites: state.favourites.favourites,
    artWallProductFeed: state.artWall.artWallProductFeed,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    query: state.router.location.query,
  };

  return props;
};

let mapDispatchToProps = (dispatch) => {
  const actions = {
    fetchProducts: fetchProducts,
    fetchMoreProducts: fetchMoreProducts,
    fetchFilters: fetchFilters,
    setFavourites,
  };

  let actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
    setProductParams: (params) => {
      dispatch(setProductParams(params));
    },
  };

  return actionMap;
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
