"use strict";

import "../sass/styles.scss";
// import 'tailwindcss/tailwind.css';
import "../../includes/resources/bootstrap/css/bootstrap-for-tailwind.css";

import Raven from "raven-js";
import initReact from "./initReact";
import outsideClick from "./lib/clickOutside";
import { toggleAbout, toggleShipping } from "./modules/toggleAbout";
import toggleThis from "./modules/toggleThis";
import matchHeights from "./modules/matchHeights";
import toggleSidebar from "./modules/toggleSidebar";
import pinterestClick from "./modules/pinterestClick";
import socialList from "./modules/socialList";
import { scrollToShop } from "./modules/scrollTo";
// import { changeLocation, selectSizeHandler } from './modules/changeLocation';
// import customCurrencyCookie from './modules/customCurrencyCookie';
import shippingHover from "./modules/shippingHoverInfo";
import toggleTopBar from "./modules/toggleTopBar";
import {
  warpFragmentRefresh,
  addToCartSubmitCb,
} from "./modules/ajaxAddToCart";
import mountMiniCart from "./modules/miniCart";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Colcade from "colcade";
import Blazy from "blazy";
import axios from "axios";
import { endpoints } from "./lib/globals";
// import anime from 'animejs/lib/anime.es.js';

// Raven.config('https://67739830004d4b6b8bdaf885dc8a43a3@sentry.stromworks.com/134').install()

document.onreadystatechange = () => {
  // DOM is ready
  console.log("READY STATE", document.readyState);
  if (document.readyState == "interactive") {
    initReact();
  }
};

// Start execunting nescessary functions
window.onload = (e) => {
  // DOM and content is loaded
  console.log("on LOAD -> READY STATE", document.readyState);
  if (document.readyState == "complete") {
    // initReact();

    const dpgKlaviyoItem = () => {
      const dpgKlaviyoForm = document.getElementById(
        "dpg-klaviyo-sign-up-form"
      );

      if (dpgKlaviyoForm) {
        dpgKlaviyoForm.addEventListener("submit", (e) => {
          e.preventDefault();

          const email = document.getElementById("dpg-klaviyo-sign-up-input");

          const dpgKlaviyoSubmit = document.getElementById(
            "dpg-klaviyo-sign-up-button"
          );
          const dpgKlaviyoSubmitText = dpgKlaviyoSubmit.textContent;

          dpgKlaviyoSubmit.disabled = true;

          if (email.value) {
            let headers = new Headers();
            let body = {
              email: email,
            };

            headers.append("Content-Type", "application/json");

            fetch(endpoints.mailchimp, {
              method: "POST",
              headers: headers,
              mode: "cors",
              cache: "default",
              body: JSON.stringify(body),
            })
              .then((response) => response.json())
              .then((json) => {
                console.log("json", json);
                const { statusCode } = json;

                switch (statusCode) {
                  case 201:
                  case 200:
                    dpgKlaviyoSubmit.textContent = "Thank you!";
                    setTimeout(() => {
                      dpgKlaviyoSubmit.textContent = dpgKlaviyoSubmitText;
                      dpgKlaviyoSubmit.disabled = false;
                    }, 5000);
                    break;

                  case 501:
                    dpgKlaviyoSubmit.textContent(
                      "Oops, we encountered an error with our newsletter service. Please try again, or contact us if the issue persists."
                    );
                    setTimeout(() => {
                      dpgKlaviyoSubmit.textContent = dpgKlaviyoSubmitText;
                      dpgKlaviyoSubmit.disabled = false;
                    }, 5000);
                    break;

                  case 500:
                    dpgKlaviyoSubmit.textContent =
                      "Please enter a valid email address.";
                    setTimeout(() => {
                      dpgKlaviyoSubmit.textContent = dpgKlaviyoSubmitText;
                      dpgKlaviyoSubmit.disabled = false;
                    }, 5000);
                    break;

                  default:
                    break;
                }
              });

            email.value = "";
          } else {
            dpgKlaviyoSubmit.textContent =
              "Please enter a valid email address.";
            setTimeout(() => {
              dpgKlaviyoSubmit.textContent = dpgKlaviyoSubmitText;
              dpgKlaviyoSubmit.disabled = false;
            }, 5000);
          }
        });
      }
    };
    dpgKlaviyoItem();

    var bLazy = new Blazy({
      offset: 100,
    });

    //Currency converter for checkout page
    if (jQuery("body").hasClass("woocommerce-checkout")) {
      currencyConverterFunc();

      jQuery("body").on(
        "blur change",
        "#billing_email_confirmation",
        function () {
          const wrapper = jQuery(this).closest(".form-row");
          const email = jQuery("#billing_email").val();
          const email_confirm = jQuery(this).val();
          if (email_confirm !== email) {
            wrapper.removeClass("woocommerce-validated");
            wrapper.addClass(
              "woocommerce-invalid woocommerce-invalid-required-field"
            );
          } else {
            wrapper.addClass("woocommerce-validated");
            wrapper.removeClass(
              "woocommerce-invalid woocommerce-invalid-required-field"
            );
          }
        }
      );

      // jQuery(document.body).on('change', 'select#billing_country', function () {
      //   // Get the custom notices
      //   const minNotice = jQuery('#country-notice-min');
      //   const maxNotice = jQuery('#country-notice-max');
      //   jQuery(minNotice).css('display', 'none');
      //   jQuery(maxNotice).css('display', 'none');
      //   // Get the selected country value
      //   const selectedCountry = jQuery(this).val();

      //   // Perform your desired action here
      //   console.log('Selected country:', selectedCountry);
      //   if (selectedCountry === 'GB') {
      //     const show = Boolean(jQuery(minNotice).data('warning'))
      //     if (show) {
      //       jQuery(minNotice).css('display', 'block');
      //     }
      //   }
      //   if (selectedCountry === 'TR') {
      //     const show = Boolean(jQuery(maxNotice).data('warning'))
      //     if (show) {
      //       jQuery(maxNotice).css('display', 'block')
      //     }
      //   }
      // });
    }

    //Currency converter for cart page
    if (jQuery("body").hasClass("woocommerce-cart")) {
      currencyConverterFunc();
      var input = jQuery("#refresh");
      if (input.val() == "yes") {
        jQuery(".cart_table").load(
          location.href + " .cart_table>*",
          "",
          function (data) {
            currencyConverterFunc();
          }
        );
      } else {
        input.val("yes");
      }
    }

    jQuery("#mini-cart")
      .find("span.woocommerce-Price-amount")
      .removeClass("amount");
    jQuery("#mini-cart #onlyOne")
      .find("span.woocommerce-Price-amount")
      .addClass("amount");

    //Currency converter on updated checkout
    jQuery("body").on("updated_checkout", function (e) {
      currencyConverterFunc();
    });

    //Currency converter on updated cart totals
    jQuery("body").on("updated_cart_totals", function (e) {
      currencyConverterFunc();
    });

    //Currency converter for sales products and single products
    if (jQuery("body").hasClass("single-product")) {
      jQuery("#originalprice")
        .find("span.woocommerce-Price-amount")
        .removeClass("amount");
      jQuery("#approximateprice del").remove();
    }

    if (jQuery("body").hasClass("single-product")) {
      const params = new URLSearchParams(window.location.search);
      const sizeKey = "attribute_pa_size";

      if (params.has(sizeKey)) {
        const sizeValue = params.get(sizeKey);
        const otherVariations = jQuery(
          `div.variationprice[data-size!="${sizeValue}"]`
        );
        jQuery(otherVariations).hide();
      } else {
        jQuery("div.variationprice").hide();
      }

      jQuery("div.variationprice")
        .find("span.woocommerce-Price-amount")
        .removeClass("amount");
      jQuery("p#approximateprice")
        .find("span.woocommerce-Price-amount")
        .addClass("amount");

      jQuery(".variations_form").on("change", "#pa_size", function (e) {
        jQuery("div.variationprice").hide();
        var variation_id = jQuery('input[name="variation_id"]').val();

        jQuery("#" + variation_id).toggle();

        if (!jQuery(this).val()) {
          jQuery(".currencywidget_container").addClass("hidden");
          jQuery(".availability").addClass("hidden");
        } else {
          jQuery(".currencywidget_container").removeClass("hidden");
          jQuery(".availability").removeClass("hidden");
        }
      });

      // /* Trick woocom single product to not render variation image of variable products (AWD frame images not suitable for SPP) */
      // let $form = jQuery("form.variations_form");
      // setTimeout(() => {
      //   $form.wc_variations_image_update(false);
      // }, 0);
      // $form.on("update_variation_values", function (e) {
      //   let variationForm = jQuery(e.currentTarget);
      //   setTimeout(() => {
      //     variationForm.wc_variations_image_update(false);
      //   }, 0);
      // });
    }

    function currencyConverterFunc() {
      jQuery("span").removeClass("amount");
      jQuery("span#onlyOne")
        .find("span.woocommerce-Price-amount")
        .addClass("amount");
    }

    // Polyfill for browsers not supporting second parameter of classList.toggle
    /*!function() {
          let t = document.createElement('_')
          t.classList.toggle( 'x', true )
          if (!t.classList.contains('x')) {
            let _t = DOMTokenList.prototype.toggle
            console.log(_t)
            DOMTokenList.prototype.toggle = function(c, f) {
              if (arguments[1] && !this.contains(c) === !f) {
                return f
              }
              return _t.call(this, c)
            }
          } else {
            console.log('contains class')
          }
        }() */

    // if (window.devicePixelRatio) {
    //   console.log('devicePixelRatio', devicePixelRatio);
    // }

    // Functionality relating to offcanvas menu & offcanvas filters (mobile)
    let menuToggle = document.getElementById("offcanvas__toggle");
    // let offCanvas = document.getElementById('offcanvas')
    // let body = document.getElementById('top')
    // let header = document.getElementById('header')

    const clickMaster = new outsideClick();

    // if (!!menuToggle && !!offCanvas) {
    if (!!menuToggle) {
      // let toggleOffCanvas = toggleThis.call(offCanvas)
      let toggleMenuToggle = toggleThis.call(menuToggle);
      // let toggleBody = toggleThis.call(body)

      // clickMaster.add(offCanvas, function(e) {
      //   if(offCanvas.classList.contains('offcanvas--toggled') && window.matchMedia("(min-width: 768px)").matches) {
      //     // toggleOffCanvas(false);
      //     toggleMenuToggle(false);
      //     // toggleBody(false);
      //   }
      // })

      menuToggle.addEventListener("click", (e) => {
        if (e.target.classList.contains("offcanvas__toggle--toggled")) {
          // toggleOffCanvas(false);
          toggleMenuToggle(false);
          // toggleBody(false);
          toggleSidebar(false);
        } else {
          // toggleOffCanvas(true);
          toggleMenuToggle(true);
          // toggleBody(true);
        }
      });
    }

    // Functionality relating to scroll animation
    let shopLink = document.getElementById("shop_link");

    if (shopLink) {
      shopLink.addEventListener("click", (event) => {
        if (window.location.pathname === "/") {
          event.preventDefault();
          scrollToShop();
        }
      });
    }

    // Mini-cart Functionality
    mountMiniCart(clickMaster, e);

    let addToCartBtn = jQuery(".single-product form.cart");
    if (addToCartBtn) {
      jQuery(addToCartBtn).on(
        "submit",
        { clickMaster: clickMaster },
        addToCartSubmitCb
      );
    }
    let ArtWallAddToCart = jQuery(
      ".dynamic-page-grid-buy-button-wrapper .buy-button"
    );
    if (ArtWallAddToCart) {
      jQuery(ArtWallAddToCart).on(
        "click",
        { clickMaster: clickMaster },
        addToCartSubmitCb
      );
    }

    // let addToCartBtn = jQuery('.single-product form.cart')
    // if (addToCartBtn.length > 0) {
    //   jQuery(addToCartBtn).on('submit', {clickMaster: clickMaster}, addToCartSubmitCb)
    // }

    // let cartTriggers = jQuery('.upsell-trigger')
    // if (cartTriggers.length > 0) {
    //   let onShow = el => {
    //     // console.log('clickmaster el', el)
    //     clickMaster.add(el, () => {
    //       // console.log('before add el', el)
    //       toggleUpsellModal(false)
    //       clickMaster.remove(el)
    //     })
    //   }
    //   let toggleModalCart = (e) => {
    //     e.preventDefault();
    //     let currentTarget = e.currentTarget
    //     let id = currentTarget.dataset.id
    //     let modal = document.getElementById('upsell-modal')
    //     modal.dataset.id = id;
    //     toggleUpsellModal(true, onShow)
    //   }
    //   jQuery(cartTriggers).on('click', toggleModalCart)
    // }
    // Refresh cart to get non-cached cart value
    if (document.querySelector(".minicart")) {
      // console.log('we be waerping!!')
      jQuery.ajax(warpFragmentRefresh).then(() => {
        mountMiniCart(clickMaster);
      });
    }

    if (jQuery(".about-artist")) {
      toggleAbout();
    }
    if (jQuery(".about-shipping")) {
      toggleShipping();
    }

    // setTimeout(matchHeights, 500);

    pinterestClick();

    socialList();

    // Custom Currency Cookie logix

    // customCurrencyCookie();

    // let countrySelect = document.getElementById('countries');
    // if(countrySelect){
    //   // console.log('ready to add changeLocation event listener', countrySelect);
    //   countrySelect.addEventListener('change', changeLocation);
    // }
    // let selectSize = document.getElementById('pa_size')
    // if (selectSize) {
    //   // console.log('attach the event to the size handler')
    //   selectSize.addEventListener('change', selectSizeHandler)
    // }

    // let topBarCountrySelect = document.getElementById('top-bar_countries');
    // if(topBarCountrySelect){
    //   // console.log('ready to add changeLocation event listener topbar', topBarCountrySelect);
    //   topBarCountrySelect.addEventListener('change', changeLocation);
    // }

    //Add classes to checkout form-rows
    jQuery("#billing_postcode_field, #shipping_postcode_field")
      .removeClass("form-row-wide")
      .addClass("form-row-first");
    jQuery("#billing_city_field, #shipping_city_field")
      .removeClass("form-row-wide")
      .addClass("form-row-last");

    //Add class to clear selection on variable products
    // console.log('pa_size: '+jQuery('#pa_size').val());
    let pa_size = jQuery("#pa_size").val();
    jQuery("#pa_size").on("change", function () {
      pa_size = jQuery(this).val();
      if (pa_size) {
        jQuery(".reset_variations").addClass("selected");
        jQuery(".reset_variations").on("click", function () {
          jQuery(this).removeClass("selected");
        });
      } else {
        jQuery(".reset_variations").removeClass("selected");
      }
    });

    //Add flag icon to country selection top bar
    if (document.getElementById("top-bar_countries")) {
      let flag_country = document
        .getElementById("top-bar_countries")
        .value.toLowerCase();
      jQuery("#flag").attr("class", "flag-icon flag-icon-" + flag_country);
    }

    shippingHover();

    let dgs = [];
    const grids = document.querySelectorAll(".dynamic-grid.masonry");
    Array.from(grids).forEach((grid) => {
      if (grid) {
        const dg = new Masonry(grid, {
          itemSelector: ".grid-item",
          columnWidth: ".column-sizer",
          gutter: ".gutter-sizer",
          percentPosition: true,
        });

        setTimeout(() => {
          imagesLoaded(grid).on("progress", () => {
            // console.log('dg', dg);
            dg.layout();
          });
        }, 1000);

        dgs.push(dg);
      }
    });

    const targetElement = document.getElementById("grid");

    const observer = new MutationObserver((mutationsList, observer) => {
      if (mutationsList.some((mutation) => mutation.target === targetElement)) {
        dgs.forEach((dg) => {
          setTimeout(() => {
            imagesLoaded(targetElement).on("progress", () => {
              dg.layout();
              observer.disconnect();
            });
          }, 0);
        });
      }
    });

    const reactAppRoot = document.getElementById("app");
    if (reactAppRoot) {
      observer.observe(reactAppRoot, { childList: true, subtree: true });
    }

    // const journalGrid = document.querySelector('.journal-grid__masonry')
    // if (journalGrid) {
    //   new Colcade( journalGrid, {
    //     columns: '.journal-grid__masonry__col',
    //     items: '.journal-grid__masonry__item',
    //   })
    // }

    const entrySummary = document.querySelector(".entry-summary");
    if (entrySummary) {
      const iconWrapper = document.querySelector(".icon-wrapper");
      if (iconWrapper) {
        const iconOutline = iconWrapper.querySelector(".icon-outline");
        const iconFill = iconWrapper.querySelector(".icon-fill");
        const productId = iconOutline.getAttribute("data-id");

        let favouriteIds;
        if (localStorage.getItem("favouriteIds")) {
          favouriteIds = JSON.parse(localStorage.getItem("favouriteIds"));
        } else {
          favouriteIds = [];
        }

        if (iconOutline && iconFill) {
          if (
            favouriteIds.find((f) => parseInt(f.id) === parseInt(productId))
          ) {
            iconOutline.classList.add("is-favourite");
            iconFill.classList.add("is-favourite");
          }

          document.addEventListener("click", (e) => {
            if (e.target.nodeName == "path") {
              if (e.target.parentElement != iconWrapper.children[0]) {
                return;
              }
            } else {
              if (e.target != iconWrapper.children[0]) return;
            }

            if (
              iconWrapper.children[0].classList.contains("is-favourite") &&
              iconWrapper.children[1].classList.contains("is-favourite")
            ) {
              console.log("remove");
              iconWrapper.children[0].classList.remove("is-favourite");
              iconWrapper.children[1].classList.remove("is-favourite");
              favouriteIds = favouriteIds.filter(
                (f) => parseInt(f.id) !== parseInt(productId)
              );
              localStorage.setItem(
                "favouriteIds",
                JSON.stringify(favouriteIds)
              );
            } else {
              console.log("add");
              iconWrapper.children[0].classList.add("is-favourite");
              iconWrapper.children[1].classList.add("is-favourite");

              const variationsDropDown = document.getElementById("pa_size");
              const isVariable = !!variationsDropDown ? true : false;
              const variationIdHiddenInput = document.querySelector(
                'input[name="variation_id"]'
              );
              const variationId =
                !!variationIdHiddenInput && !!variationIdHiddenInput.value
                  ? variationIdHiddenInput.value
                  : "";
              const variationSize =
                variationsDropDown &&
                variationsDropDown[variationsDropDown.selectedIndex]
                  .textContent !== "SELECT SIZE"
                  ? variationsDropDown[variationsDropDown.selectedIndex]
                      .textContent
                  : "";
              const variationSizeSlug =
                variationsDropDown &&
                !!variationsDropDown[variationsDropDown.selectedIndex].value
                  ? variationsDropDown[variationsDropDown.selectedIndex].value
                  : "";
              console.log("before push object", favouriteIds);
              favouriteIds.push({
                id: parseInt(productId),
                isVariable: isVariable,
                variationId: variationId,
                variationSize: variationSize,
                variationSizeSlug: variationSizeSlug,
              });
              console.log("after push object", favouriteIds);
              localStorage.setItem(
                "favouriteIds",
                JSON.stringify(favouriteIds)
              );
              console.log("after set localstorage ", favouriteIds);
            }
          });
        }
      }
    }

    const customPostTypeProducts = document.querySelectorAll(
      ".custom-post-type-product"
    );
    const customPostTypeProductsArray = Array.from(customPostTypeProducts);
    if (!!customPostTypeProductsArray.length) {
      let favouriteIds;
      if (localStorage.getItem("favouriteIds")) {
        favouriteIds = JSON.parse(localStorage.getItem("favouriteIds"));
      } else {
        favouriteIds = [];
      }

      customPostTypeProductsArray.map((cPTP, i) => {
        const iconWrapper =
          customPostTypeProductsArray[i].querySelector(".icon-wrapper");
        if (iconWrapper) {
          const iconOutline = iconWrapper.querySelector(".icon-outline");
          const iconFill = iconWrapper.querySelector(".icon-fill");

          if (iconOutline && iconFill) {
            const productId = iconOutline.getAttribute("data-id");

            if (
              favouriteIds.find((f) => parseInt(f.id) === parseInt(productId))
            ) {
              iconOutline.classList.add("is-favourite");
              iconFill.classList.add("is-favourite");
            }

            document.addEventListener("click", (e) => {
              if (e.target.nodeName == "path") {
                if (e.target.parentElement != iconWrapper.children[0]) {
                  return;
                }
              } else {
                if (e.target != iconWrapper.children[0]) return;
              }

              if (
                iconWrapper.children[0].classList.contains("is-favourite") &&
                iconWrapper.children[1].classList.contains("is-favourite")
              ) {
                iconWrapper.children[0].classList.remove("is-favourite");
                iconWrapper.children[1].classList.remove("is-favourite");
                favouriteIds = favouriteIds.filter(
                  (f) => parseInt(f.id) !== parseInt(productId)
                );
                localStorage.setItem(
                  "favouriteIds",
                  JSON.stringify(favouriteIds)
                );
              } else {
                iconWrapper.children[0].classList.add("is-favourite");
                iconWrapper.children[1].classList.add("is-favourite");

                const isVariable =
                  customPostTypeProductsArray[i].dataset.isVariable === "true"
                    ? true
                    : false;
                const variationId =
                  customPostTypeProductsArray[i].dataset.variationId !== "false"
                    ? customPostTypeProductsArray[i].dataset.variationId
                    : "";
                const variationSize =
                  customPostTypeProductsArray[i].dataset.variationSize !==
                  "false"
                    ? customPostTypeProductsArray[i].dataset.variationSize
                    : "";
                const variationSizeSlug =
                  customPostTypeProductsArray[i].dataset.variationSizeSlug !==
                  "false"
                    ? customPostTypeProductsArray[i].dataset.variationSizeSlug
                    : "";

                favouriteIds.push({
                  id: parseInt(productId),
                  isVariable: isVariable,
                  variationId: variationId,
                  variationSize: variationSize,
                  variationSizeSlug: variationSizeSlug,
                });

                localStorage.setItem(
                  "favouriteIds",
                  JSON.stringify(favouriteIds)
                );
              }
            });
          }
        }
      });
    }

    /*if (!Modernizr.svg) {
          let fallback = jQuery('.header-logo').data('fallback-url');
          jQuery("img[src$='.svg']")
            .attr("src", fallback);
        }*/

    // const shippingCountry = document.getElementById('billing_country')
    // console.log('shipping country ', shippingCountry)
    // if (shippingCountry) {
    //   shippingCountry.addEventListener('input', (e) => {
    //     console.log('value ', e.target.value)
    //   })
    // }

    const russiaNotice = document.querySelector(".russia-notice");
    if (russiaNotice) {
      jQuery("#billing_country, #shipping_country").on(
        "select2:select",
        function (e) {
          if (e.target.value == "RU") {
            russiaNotice.classList.add("show");
          }
        }
      );

      const russiaNoticeClose = document.getElementById("russia-notice-close");
      russiaNoticeClose.addEventListener("click", () => {
        russiaNotice.classList.remove("show");
      });
    }

    // Remove copy paste from email confirmation field in chekcout
    const emailConfirmationField = document.getElementById(
      "billing_email_confirmation"
    );
    if (emailConfirmationField) {
      emailConfirmationField.onpaste = (e) => {
        e.preventDefault();
      };
    }

    // Checkout disclaimer
    const disclaimerAcceptWrapper = document.getElementById(
      "order-review-disclaimer__accept"
    );
    const disclaimerPopup = document.getElementById("order-review-disclaimer");
    if (disclaimerPopup) {
      const disclaimerAccept =
        disclaimerPopup.querySelector("#accept-disclaimer");

      disclaimerAccept.addEventListener("change", (event) => {
        if (event.currentTarget.checked) {
          disclaimerAcceptWrapper.classList.add("accepted");
          setTimeout(() => {
            disclaimerPopup.classList.remove("active");
            disclaimerAcceptWrapper.classList.remove("accepted");
          }, 500);
        }
      });

      jQuery("body").on("updated_checkout", (event) => {
        const terms = document.getElementById("terms");
        terms.addEventListener("change", (event) => {
          if (event.currentTarget.checked) {
            if (!disclaimerAccept.checked) {
              disclaimerPopup.classList.add("active");
            }
          } else {
            disclaimerPopup.classList.remove("active");
          }
        });
      });
    }

    const currencyChanger = document.getElementById("currencyChanger");
    if (currencyChanger) {
      document.addEventListener("change", (e) => {
        if (e.target !== currencyChanger) return;
        const approxPriceElements = document.querySelectorAll(
          ".price.price--approx"
        );
        Array.from(approxPriceElements).map((aPE) => {
          aPE.textContent = "Converting currency...";
        });
        const approxPriceWrapper = document.querySelectorAll(
          ".approx-price-wrapper"
        );
        if (approxPriceWrapper) {
          Array.from(approxPriceWrapper).map((aPW) => {
            const hiddenInputField = aPW.querySelector(
              ".approx-price-hidden-field"
            );

            const data = hiddenInputField.value;
            const type = hiddenInputField.dataset.type;
            const shipping = hiddenInputField.dataset.shipping;

            const approxPriceElement = aPW.querySelector(
              ".price.price--approx"
            );

            const body = {
              currencyCode: e.target.value,
              ...(data && { data: data }),
              ...(type && { type: type }),
              ...(shipping && { shipping: shipping }),
            };

            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            if (typeof wcCurrencyNonce !== "undefined") {
              headers.append("X-WP-Nonce", wcCurrencyNonce);
            }

            fetch("/wp-json/country-picker/price", {
              method: "POST",
              credentials: "include",
              headers: headers,
              body: JSON.stringify(body),
            })
              .then((response) => response.json())
              .then((approxPrice) => {
                if (!approxPrice) {
                  approxPriceElement.textContent = "";
                } else if (approxPrice.code) {
                  approxPriceElement.textContent = "";
                } else {
                  approxPriceElement.textContent = `Approx. ${approxPrice}`;
                }
              });
          });
        }
      });
    }

    //max40Characters start
    // let mountState = false

    const billingAddress = document.getElementById("billing_address_1");
    const shippingAddress = document.getElementById("shipping_address_1");
    // const shippingAddressWrapper = document.querySelector('.shipping_address')

    const max40CharactersValidation = (
      element,
      errorElement,
      id = null,
      immediately
    ) => {
      if (immediately) {
        let value = element.value;

        if (value && value.length === 40) {
          console.log("errorElement", errorElement);
          if (id && !document.getElementById(id)) {
            element.after(errorElement);
          }
          max40CharactersValidation(element, errorElement, false);
        }
      } else {
        element.addEventListener("input", (e) => {
          let value = e.target.value;

          if (value && value.length === 40) {
            if (document.getElementById("billing_address_1")) {
            }
            element.after(errorElement);
          } else {
            errorElement.remove();
          }
        });
      }
    };

    const initMax40CharactersValidation = (
      billingAddress,
      id,
      immediately = false
    ) => {
      const errorElement = document.createElement("span");
      errorElement.setAttribute("id", id);
      errorElement.setAttribute("class", "max-40-characters-error");
      const iconElement = document.createElement("i");
      iconElement.setAttribute("class", "fa-light fa-circle-exclamation");
      errorElement.appendChild(iconElement);
      const content = document.createTextNode(
        "Max 40 characters allowed per line"
      );
      errorElement.appendChild(content);

      max40CharactersValidation(billingAddress, errorElement, id, immediately);
    };

    if (billingAddress) {
      const id = "billing-max-40-characters-error";
      initMax40CharactersValidation(billingAddress, id);
    }

    if (shippingAddress) {
      const id = "shipping-max-40-characters-error";
      initMax40CharactersValidation(shippingAddress, id);
    }

    // jQuery('body').on('updated_checkout', () => {
    //   if (shippingAddress && shippingAddressWrapper.style.display === '') {
    //     if (!mountState) {
    //       const id = 'shipping-max-40-characters-error'
    //       initMax40CharactersValidation(shippingAddress, id, true)
    //       mountState = true
    //     }
    //   }
    // })
    //max40Characters end

    const seoTextBlock = document.querySelector(".seo-text-block");
    const seoTextButton = document.querySelector(".seo-text-button");
    const seoTextBlockOverlay = document.querySelector(
      ".seo-text-block-overlay"
    );

    let seoTextState = false;

    if (seoTextBlock) {
      seoTextButton.addEventListener("click", () => {
        if (seoTextState) {
          seoTextBlock.style.maxHeight = 160 + "px";
          seoTextButton.textContent = "Read more";
          seoTextBlockOverlay.style.opacity = 100;
        } else {
          seoTextBlock.style.maxHeight = seoTextBlock.scrollHeight + "px";
          seoTextButton.textContent = "Read less";
          seoTextBlockOverlay.style.opacity = 0;
        }

        seoTextState = !seoTextState;
      });
    }
  }

  // //HACK FIX OF MOBILE ADD TO CART BTN
  // //TODO REMOVE WHEN LAUNCHING AJAX ADD TO CART
  // const mobileAddToCartWrapper = document.getElementById('add-to-cart-top')
  // const siblingForm = document.querySelector('.variations_form.cart')
  // if (!!mobileAddToCartWrapper && !!siblingForm) {
  //   const form = mobileAddToCartWrapper.querySelector('form')
  //   if (!!form) {
  //     form.addEventListener('submit', function(e) {
  //       console.log('LISTEN');
  //       e.preventDefault();
  //       if (!!siblingForm) {
  //         siblingForm.submit();
  //       } else {
  //         e.currentTarget.submit();
  //       }
  //     })
  //   }
  // }

  // HACK Fix variable product issue
  const handleVariationmax40Charac = function () {
    const handleAddToCartClick = function (addToCartBtns, currentSize) {
      console.log("current size", currentSize);
      for (let i = 0; addToCartBtns.length > i; i++) {
        const acb = addToCartBtns[i];
        if (!currentSize && !acb.classList.contains("disabled")) {
          acb.classList.add("disabled");
        } else if (currentSize && acb.classList.contains("disabled")) {
          acb.classList.remove("disabled");
        }
      }
    };
    const addToCartBtns = document.querySelectorAll(
      ".single_add_to_cart_button"
    );
    const hasVariations = document.querySelector(".product-type-variable");
    if (addToCartBtns && hasVariations) {
      const sizeSelect = document.getElementById("pa_size");
      const currentSize = sizeSelect.options[sizeSelect.selectedIndex].value;
      handleAddToCartClick(addToCartBtns, currentSize);
      sizeSelect.addEventListener("change", function (e) {
        const size =
          e.currentTarget.options[e.currentTarget.selectedIndex].value;
        handleAddToCartClick(addToCartBtns, size);
      });
      for (let i = 0; addToCartBtns.length > i; i++) {
        addToCartBtns[i].addEventListener("click", function (e) {
          if (e.currentTarget.classList.contains("disabled")) {
            console.log("variation needed");
            e.preventDefault();
          }
        });
      }
    }
  };

  // handleVariationmax40Charac()

  const singleProductAddToCartButtons = document.querySelectorAll(
    ".single_add_to_cart_button"
  );
  Array.from(singleProductAddToCartButtons).map(
    (singleProductAddToCartButton) => {
      if (singleProductAddToCartButton && typeof gsc === typeof Function) {
        singleProductAddToCartButton.addEventListener("click", (e) => {
          console.log("inside delegation: continue");

          let hasFrames = singleProductAddToCartButton.dataset.frames;

          if (localStorage.getItem("gscFramePushActivated") == "true") {
            const gscTime = 1000 * 60 * 60 * 24;
            if (
              parseInt(localStorage.getItem("gscFramePushTime")) + gscTime <=
              Date.now()
            ) {
              localStorage.setItem("gscFramePushActivated", "false");
            }
          } else {
            if (hasFrames == "false") {
              setTimeout(() => {
                gsc("show", 78383);
              }, 1000 * 3);
              localStorage.setItem("gscFramePushActivated", "true");
              localStorage.setItem(
                "gscFramePushTime",
                JSON.stringify(Date.now())
              );
            } else {
              singleProductAddToCartButton.dataset.frames = "true";
            }
          }
        });
      }
    }
  );

  const EUCountryCodes = [
    "BE",
    "BG",
    "CZ",
    "DK",
    "DE",
    "EE",
    "IE",
    "EL",
    "ES",
    "FR",
    "HR",
    "IT",
    "CY",
    "LV",
    "LT",
    "LU",
    "HU",
    "MT",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "FI",
    "SE",
  ];

  //task no 210422

  const changeAddressPlaceholderOnMobile = () => {
    if (jQuery(window).width() < 360) {
      if (jQuery("input#billing_address_1"))
        jQuery("input#billing_address_1").attr(
          "placeholder",
          "Address 1 (Street / House No. / Floor)*"
        );

      if (jQuery("input#billing_address_2"))
        jQuery("input#billing_address_2").attr(
          "placeholder",
          "Address 2 (Additional address info)"
        );
    } else {
      if (jQuery("input#billing_address_1"))
        jQuery("input#billing_address_1").attr(
          "placeholder",
          "Address 1 (Street name / House number / Floor)*"
        );

      if (jQuery("input#billing_address_2"))
        jQuery("input#billing_address_2").attr(
          "placeholder",
          "Address 2 (Additional address information)"
        );
    }

    jQuery(window).on("resize", function () {
      if (jQuery(window).width() < 360) {
        if (jQuery("input#billing_address_1"))
          jQuery("input#billing_address_1").attr(
            "placeholder",
            "Address 1 (Street / House No. / Floor)*"
          );

        if (jQuery("input#billing_address_2"))
          jQuery("input#billing_address_2").attr(
            "placeholder",
            "Address 2 (Additional address info)"
          );
      } else {
        if (jQuery("input#billing_address_1"))
          jQuery("input#billing_address_1").attr(
            "placeholder",
            "Address 1 (Street name / House number / Floor)*"
          );

        if (jQuery("input#billing_address_2"))
          jQuery("input#billing_address_2").attr(
            "placeholder",
            "Address 2 (Additional address information)"
          );
      }
    });
  };

  changeAddressPlaceholderOnMobile();

  //task no 210422 end

  const countryPickerListener = (element, EUCountryCodes) => {
    element.addEventListener(
      "change",
      function (e) {
        if (
          EUCountryCodes.includes(e.target.value) &&
          typeof gsc === typeof Function
        ) {
          gsc("show", 78551);
        } else {
          gsc("show", 86343);
        }
      },
      false
    );
  };

  const WCCountryListener = (EUCountryCodes) => {
    jQuery("#billing_country, #shipping_country").on(
      "select2:select",
      function (e) {
        if (
          EUCountryCodes.includes(e.target.value) &&
          typeof gsc === typeof Function
        ) {
          gsc("show", 78551);
        } else {
          gsc("show", 86343);
        }
      }
    );
  };

  const checkoutPage = document.querySelector(".page-checkout");
  if (checkoutPage && typeof gsc === typeof Function) {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("wc_ship_to_country"))
      .split("=")[1];

    const cP = document.getElementById("country-picker");
    // const bC = document.getElementById('billing_country')
    // console.log('wc_ship_to_country', cookieValue);

    setTimeout(() => {
      if (EUCountryCodes.includes(cookieValue)) {
        gsc("show", 78551);
      } else {
        gsc("show", 86343);
      }
    }, 1000 * 35);

    countryPickerListener(cP, EUCountryCodes);
    WCCountryListener(EUCountryCodes);
  }

  // const frontpageGrid = document.querySelector('.frontpage-grid')
  // if (frontpageGrid) {
  //   anime({
  //     targets: '.frontpage-grid a',
  //     translateX: 270,
  //     delay: anime.stagger(100)
  //   });
  // }

  // task 2362164028 start sebastianp

  const showInches = () => {
    const productSelect = document.querySelector("select#pa_size");
    const spanToDisplayInches = document.querySelector("div.display-inches");
    const resetVariations = document.querySelector(".reset_variations");

    if (resetVariations) {
      resetVariations.addEventListener(
        "click",
        (e) => {
          spanToDisplayInches.innerHTML = "";
        },
        true
      );
    }
    if (productSelect) {
      productSelect.addEventListener(
        "change",
        (e) => {
          const selectedOption = e.target.options[e.target.selectedIndex];
          if (selectedOption.dataset.showInches !== "") {
            spanToDisplayInches.innerHTML = selectedOption.dataset.showInches;
          } else {
            selectedOption.dataset.showInches = "";
          }
        },
        true
      );
    }
  };
  showInches();
  // task 2362164028 end sebastianp

  //const iframe = jQuery('#hero iframe');
  // const iframe = document.querySelector('#hero iframe');
  // if (iframe) {
  //   const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  //   if (  iframeDoc.readyState  == 'complete' ) {
  //     //iframe.contentWindow.alert("Hello");
  //     iframe.contentWindow.onload = function(){
  //       document.querySelector('.tpc-header-inner').classList.remove('bg-dark-important');
  //         console.log("I am loaded");
  //     };
  //     // The loading is complete, call the function we want executed once the iframe is loaded
  //     // console.log(document.querySelector('.tpc-header-inner'));
  //     if(document.querySelector('.tpc-header-inner'))
  //       document.querySelector('.tpc-header-inner').classList.remove('bg-dark-important');
  //     return;
  //   }
  // }

  /* OUT COMMENT BEFORE PRODUCTION PUSH */
  // jQuery('#hero iframe').on('load', ()=> {
  //   jQuery('.bg-dark-important').removeClass('bg-dark-important');
  // });

  const setIframeParameters = () => {
    const portraitIframes = jQuery(".homestory-iframe");
    if (portraitIframes) {
      portraitIframes.each(function (index) {
        fetch(
          "https://vimeo.com/api/oembed.json?url=" + jQuery(this).attr("src"),
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((iframeData) => {
            if (iframeData) {
              if (iframeData.height > iframeData.width) {
                const portraintParentWidth = jQuery(this).parent().width();
                console.log(jQuery(this).parent().width());
                jQuery(this).height(
                  portraintParentWidth * (iframeData.height / iframeData.width)
                );
                jQuery(this).width(portraintParentWidth);
              } else if (iframeData.height < iframeData.width) {
                console.log(jQuery(this));
                const landscapeParentWidth = jQuery(this).parent().width();
                jQuery(this).height(
                  (landscapeParentWidth * iframeData.height) / iframeData.width
                );
                jQuery(this).width(landscapeParentWidth);
              }
            }
          });
      });
    }
  };

  // set the iframe height on the frontpage
  const setCorrectVideoHeightOnFrontpage = () => {
    const frontPageVideo = jQuery(".home .frontpage-row--fullwidth .has-video");
    const responsiveContentHeight = jQuery(
      ".home .frontpage-row--fullwidth .responsive-content"
    ).height();
    const parentWidth = frontPageVideo.parent().width();

    jQuery(
      ".home .frontpage-row--fullwidth .has-video .desktop-content"
    ).height(parentWidth * 0.56);
  };

  setIframeParameters();
  if (jQuery(window).width() < 769) setCorrectVideoHeightOnFrontpage();

  jQuery(window).on("resize", function () {
    setIframeParameters();
    if (jQuery(window).width() < 769) setCorrectVideoHeightOnFrontpage();
  });

  // const setHeaderBackground = () => {
  //   const minicart = jQuery('#cart-contents');
  //   console.log(minicart)
  //   if( minicart ) {
  //     minicart.on('click', e => {
  //       if(jQuery('.minicart').hasClass('active')) {

  //         jQuery( '#header' ).addClass('bg-tpc-darker')
  //         jQuery( '.header-logo-big' ).css( 'color', 'rgb(51,51,51)' );
  //         jQuery( '.header-logo-small' ).css( 'color', 'rgb(51,51,51)' );
  //         jQuery('.hamburger-react div div').css( 'background-color', 'rgb(51,51,51)' );
  //         jQuery('.search-icon').css( 'color', 'rgb(51,51,51)' )
  //         jQuery('.header-favourite-icon-svg').css( 'color', 'rgb(51,51,51)' )
  //         jQuery('.header-favourite-icon-second-svg').css( 'color', 'rgb(51,51,51)' )
  //         jQuery('.shopping-bag-no-product').css( 'color', 'rgb(51,51,51)' )
  //         jQuery('.shopping-bag-product').css( 'color', 'rgb(51,51,51)' )

  //       } else {

  //         jQuery( '#header' ).removeClass('bg-tpc-darker')
  //         jQuery('.hamburger-react div div').css( 'background-color', 'white' );
  //         jQuery('.search-icon').css( 'color', '' )
  //         jQuery( '.header-logo-big' ).css( 'color', '' );
  //         jQuery( '.header-logo-small' ).css( 'color', 'white' );
  //         jQuery( '.header-favourite-icon-second-svg' ).css( 'color', '' );
  //         jQuery( '.header-favourite-icon-svg' ).css( 'color', '' );
  //         jQuery( '.shopping-bag-no-product' ).css( 'color', '' );
  //         jQuery( '.shopping-bag-product' ).css( 'color', 'white' );

  //       }
  //     })
  //   }

  // }

  // setHeaderBackground();
};

jQuery("#offcanvas").scroll(function (e) {
  let offCanvas = document.getElementById("offcanvas");
  let isToggled = offCanvas.classList.contains("offcanvas--toggled");
  if (isToggled) {
    let btn = document.getElementById("offcanvas__toggle");
    let btnTop = btn.getBoundingClientRect().top;
    let menuWrapper = offCanvas.querySelector(".offcanvas__menu-wrapper");
    let menuPaddingOffset = 32;
    let menuTop = menuWrapper.getBoundingClientRect().top;
    let menuScrollPos = menuTop - menuPaddingOffset;
    if (menuScrollPos <= btnTop && !btn.classList.contains("overlapped")) {
      btn.classList.add("overlapped");
    } else if (menuScrollPos > btnTop && btn.classList.contains("overlapped")) {
      btn.classList.remove("overlapped");
    }
  }
});

// adding slick to the product feed in the homestories
jQuery(document).ready(function () {
  const $product_feeds = jQuery(
    ".homestories .related-products, .frontpage-grid .related-products, .single-artists .related-products"
  );
  $product_feeds.each(function () {
    jQuery(this)
      .slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: true,
        prevArrow:
          "<button type='button' class='pull-left'><i class='slick-prev fa-light fa-arrow-left fa-lg'></i></button>",
        nextArrow:
          "<button type='button' class='pull-right'><i class='slick-next fa-light fa-arrow-right fa-lg'></i></button>",
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      })
      .on("setPosition", function (event, slick) {
        console.log(slick.$slideTrack.hasClass("sizes-set-up"));
        if (
          !slick.$slideTrack.hasClass("sizes-set-up") &&
          jQuery(window).width() < 1024
        ) {
          const sliderHeight = slick.$slideTrack.height();

          const slides = slick.$slideTrack.find(".slick-slide");
          slides.each(function () {
            const slideImageHeight = jQuery(this).find("img").height();
            jQuery(this)
              .find(".grid-item-footer")
              .height(sliderHeight - slideImageHeight - 30);
          });
          slick.$slideTrack.addClass("sizes-set-up");
        }
      });
  });
});

window.onload(toggleTopBar());

// jQuery(window).resize(function(){setTimeout(matchHeights,50)});

class WallLoader {
  constructor() {
    this.loader = null;
  }

  createLoader() {
    if (!this.loader) {
      this.loader = document.createElement("div");
      this.loader.className = "loading-component";
      this.loader.innerHTML = `<div class="loading-component__loader"></div>`;
    }
  }

  startLoader() {
    this.createLoader(); // Ensure the loader exists
    const wrapper = document.querySelector(".custom-post-type-items-wrapper");
    if (wrapper && !document.querySelector(".loading-component")) {
      wrapper.appendChild(this.loader);
    }
  }

  stopLoader() {
    if (this.loader && document.querySelector(".loading-component")) {
      this.loader.remove();
    }
  }

  fadeIn(elements) {
    elements.forEach((element) => {
      element.classList.add("fade-in");
      setTimeout(() => element.classList.add("visible"), 100);
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  let page = 2;
  let loading = false;
  let hasMorePosts = true;

  const wallLoader = new WallLoader();

  var bLazy = new Blazy({
    offset: 100,
  });

  const footer = document.getElementById("footer");

  window.addEventListener("scroll", function () {
    if (footer) {
      const footerHeight = footer.offsetHeight;

      const scrollThreshold =
        document.documentElement.scrollHeight - footerHeight - 100;

      if (
        !loading &&
        hasMorePosts &&
        window.scrollY + window.innerHeight > scrollThreshold
      ) {
        wallLoader.startLoader();
        const pathname = location?.pathname?.replace(/\//g, "");

        loading = true;

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/wp-admin/admin-ajax.php", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            let response = xhr.responseText;
            if (response) {
              let wrapper = document.querySelector(
                ".custom-post-type-items-wrapper"
              );
              document.querySelector(".loading-component")?.remove();
              wrapper.insertAdjacentHTML("beforeend", response);
              page++;
              loading = false;
              bLazy.revalidate();
              wallLoader.stopLoader();

              let newPosts = wrapper.querySelectorAll(".art-wall-item");
              wallLoader.fadeIn(newPosts);
            } else {
              hasMorePosts = false;
              wallLoader.stopLoader();
            }
          }
        };

        xhr.send(
          `action=load_more_custom_post_type_items&page=${page}&cpt=${pathname}`
        );
      }
    }
  });
});
