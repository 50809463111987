import { useEffect, useState } from "react";
import LoadingSpinnerComponent from "./LoadingSpinnerComponent";
import CurrencyPicker from "./currencyPicker/CurrencyPicker";

const KlaviyoSignUpComponent = ({
  handleSubmit,
  value,
  changeFunc,
  hasError,
  isAdded,
  response,
  isSubmitting,
}) => {
  const [buttonState, setButtonState] = useState("Subscribe");
  const ACTIVATE_COUNTRY_PICKER = false;

  useEffect(() => {
    isSubmitting
      ? setButtonState(<LoadingSpinnerComponent size={6} />)
      : hasError || isAdded
      ? setButtonState(response)
      : setButtonState("Subscribe");
  }, [isSubmitting, hasError, isAdded]);

  useEffect(() => {
    setTimeout(() => {
      setButtonState("Subscribe");
    }, 5000);
  }, [hasError, isAdded]);

  return (
    <form
      className="klaviyo-sign-up-form flex flex-col gap-4"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        value={value}
        onChange={changeFunc}
        onBlur={changeFunc}
        placeholder="Enter your email address"
        id="klaviyo-footer"
        className="mt-3 border border-solid border-black mb-1 h-11 bg-transparent p-3 font-body-regular text-xs placeholder-gray-light text-black outline-none"
      />
      {ACTIVATE_COUNTRY_PICKER && <CurrencyPicker />}
      <button
        type="submit"
        className="border border-solid border-black flex justify-center items-center font-display-bold text-xs tracking-widest uppercase text-black"
        style={{ height: "42px", paddingTop: "2px" }}
      >
        {buttonState}
      </button>
    </form>
  );
};

export default KlaviyoSignUpComponent;
